import React from 'react'

import {
    Button,
    Headline,
    Paragraph,
    Phone,
    TopLine,
} from '../../TeaserBoxGroup/style'
import { Flex, Box } from '@rebass/grid/emotion'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Column = (props) =>
    <Box width={[1, 1, 1 / 2, 1 / 2]} p={[30, 75, 75, 100]} {...props} />

const LeftColumn = styled(Column)`
  background-color: #4975ba;
  color: #fff;
`

const RightColumn = styled(Column)`
  background-color: #c6d7e4;
  color: rgb(68,68,68);
`

export default () =>
    <Flex flexWrap='wrap'>
        <LeftColumn>
            <article>
                <div>
                    <TopLine>Unsere Ausstattung</TopLine>
                    <Headline>
                        Die individuelle Balkonanlage für <br />Ihr Objekt.
                    </Headline>
                    <Paragraph>

                    Wir sind in der Lage, exakt auf Ihre Wunschmaße einzugehen. Unsere Balkongeländer sind individuell gestaltbar und durch unsere Beschichtungssysteme können wir nahezu jede Farbe im RAL- oder DB-Bereich anbieten.<br />

                        Hier geht es zu unseren <Link to="/ausstattung/">Ausstattungen</Link>.
                    </Paragraph>
                </div>
            </article>
        </LeftColumn>
        <RightColumn>
            <article>
                <div>
                    <TopLine>Ihr Angebot</TopLine>
                    <Headline>Individuelles Angebot für Ihre Balkonanlage.</Headline>
                    <Paragraph>
                        Gerne erstellen wir Ihnen kurzfristig ein kostenloses und unverbindliches Angebot in verschiedenen Größen und Varianten:
                    </Paragraph>
                    <Phone onClick={() => {
                        if (typeof window !== "undefined" && window.dataLayer) {
                            window.dataLayer.push({
                                'event': 'contactPhone',
                                'category': 'contactCategory',
                                'action': 'call',
                            });
                        }
                        else {
                            console.error("data layer not found")
                        }
                        }} href="tel:493429898890">+49 (0)34298-9889-0</Phone>
                    <br />
                    <Button href="/kontakt/">
                        Angebot anfordern <span />&#8594;
                    </Button>
                </div>
            </article>
        </RightColumn>
    </Flex>
