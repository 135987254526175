import styled from '@emotion/styled';

import {
    Head1Font,
    MyHeadline12,
    ParaFont,
    TopLineFont
} from '../../style/fonts';

export const Fact = {};

Fact.Headline = styled('h2')`
    ${MyHeadline12};
    text-align: center;
    margin: 0 0 2.5rem 0;
`;

export const Wrapper = styled('section')`
    display: block;
    background-color: #4975ba;

    @media (min-width: 50em) {
        display: flex;
    }
`;

export const Service = styled('article')``;

export const Offer = styled('article')``;

export const TopLine = styled('div')`
    ${TopLineFont};
    max-width: 80%;
    color: $textLight;
`;

export const Headline = styled('h1')`
    ${Head1Font};
    color: $textLight;
`;

export const Paragraph = styled('p')`
    ${ParaFont};

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    a {
        color: #fff;
    }
`;

export const Phone = styled('a')`
    display: inline-block;
    padding: 0;
    margin: 0.75rem 0;

    color: #064360;
    font-size: 2.8rem;
    font-style: normal;
    line-height: 1.8em;
    font-weight: 600;

    text-decoration: none;
`;

export const Button = styled('a')`
    display: inline-block;
    background-color: #064360;
    color: #fff;

    font-size: 1.8rem;
    font-style: normal;
    line-height: 1em;
    letter-spacing: 0.01em;
    font-weight: 600;

    padding: 1.75rem 2.5rem;
    border-radius: 0.75rem;
    margin-top: 2.25rem;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: background-color 0.3s ease, color 0.3s ease;

    :active {
        /* color: #555abf; */
        /* background-color: #f6f9fc; */
        transform: translateY(1px);
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
            0 1px 3px rgba(0, 0, 0, 0.08);
    }

    :hover {
        /*color: #7795f8;*/
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
            0 3px 6px rgba(0, 0, 0, 0.08);
    }

    > span {
        font-size: 1.8rem;
        font-style: normal;
        line-height: 1em;
        letter-spacing: 0.01em;
        font-weight: 600;

        display: inline-block;
        padding-left: 0.2em;
    }

    &:hover {
        background-color: $blue1;
        color: #textLight;
    }
`;
